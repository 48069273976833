import moment from 'moment'
import 'moment/locale/fi' // Todo: Should we change locale when language is changed?

// Returns time in [21.01.2020 17.00] format
export const formatDate = date => {
  // backend converts time to the timezone of the venue location
  return moment(date).format('L LT')
}

// Returns time in [Tue Jan 21 2020 00:00:00 GMT+0200 (Eastern European Standard Time)] format
export const parseBackendDateToDate = datestring => {
  // e.g. 2019-10-31
  if (!datestring || datestring.length < 1) return ''
  const momentDate = moment(datestring, 'YYYY-MM-DD')
  return momentDate.isValid() ? momentDate.toDate() : ''
}

export const parseBackendDateToMoment = datestring => {
  // e.g. 2019-10-31
  if (!datestring || datestring.length < 1) return ''
  const momentDate = moment(datestring, 'YYYY-MM-DD')
  return momentDate.isValid() ? momentDate : null
}

// Returns time in [21.01.2020] format
export const parseBackendDateToDDMMYYYY = datestring => {
  // e.g. 2019-10-31
  if (!datestring || datestring.length < 1) return ''
  const momentDate = moment(datestring, 'YYYY-MM-DD')
  return momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : ''
}

export const isBackendDateValid = datestring => {
  // e.g. 2019-10-31
  if (!datestring || datestring.length < 1) return false
  const momentDate = moment(datestring, 'YYYY-MM-DD')
  return momentDate.isValid() ? true : false
}

export const isTimeValid = timestring => {
  // e.g. 10:30
  if (!timestring || timestring.length < 1) return false
  const momentDate = moment(timestring, 'hh:mm')
  return momentDate.isValid() ? true : false
}

// Returns time in [17:00] format
export const parseBackendDateToTime = datestring => {
  // e.g. 2019-10-31
  if (!datestring || datestring.length < 1) return ''
  const momentDate = moment(datestring, 'YYYY-MM-DD HH.mm')
  return momentDate.isValid() ? momentDate.format('HH:mm') : ''
}
// Returns time in [2020-01-21] format
export const parseDateToBackendFormat = date => {
  // TODO: handle missing date error better
  if (!date) return ''
  const momentDate = moment(date)
  return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : ''
}

export const parseMomentToBackendFormat = momentDate => {
  return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : ''
}

export const parseBackendDateStringAndTimeStringToMoment = (datestring, timestring) => {
  // e.g. 2019-10-31 11:30
  if (!datestring || datestring.length < 1) return ''
  if (!timestring || timestring.length < 1) return ''
  const momentDate = moment(datestring + timestring, 'YYYY-MM-DD HH:mm')
  return momentDate.isValid() ? momentDate : null
}

export const parsePreReservationDateTime = dateTime => {
  // e.g. 2019-12-19T08:00:00+02:00
  if (!dateTime || dateTime.length < 1) return ''
  const momentDate = moment(dateTime)
  return momentDate.isValid() ? momentDate : null
}

export const getCurrentTime = () => {
  return moment().format()
}

export const differenceInSecondsToCurrentTime = time => {
  if (!time) return -1
  var startTime = moment(time)
  var currentTime = moment()
  return currentTime.diff(startTime, 'seconds')
}

export const isPreReservationTimeSameThanStatesTime = (reduxSearch, reduxPreReservation) => {
  if (!reduxSearch) return false
  if (!reduxSearch.reservationDate) return false
  if (!reduxSearch.startTime) return false
  if (!reduxSearch.endTime) return false
  if (!reduxPreReservation.venueReservations) return false
  if (reduxPreReservation.venueReservations.length < 1) return false

  const venueReservation = reduxPreReservation.venueReservations[0]
  if (!venueReservation.period) return false
  if (!venueReservation.period.start) return false
  if (!venueReservation.period.end) return false

  var stateStartTime = parseBackendDateStringAndTimeStringToMoment(reduxSearch.reservationDate, reduxSearch.startTime)
  if (!stateStartTime) return false
  var stateEndTime = parseBackendDateStringAndTimeStringToMoment(reduxSearch.reservationDate, reduxSearch.endTime)
  if (!stateEndTime) return false

  var preReservationStartTime = parsePreReservationDateTime(venueReservation.period.start)
  if (!preReservationStartTime) return false
  var preReservationEndTime = parsePreReservationDateTime(venueReservation.period.end)
  if (!preReservationEndTime) return false

  if (stateStartTime.isSame(preReservationStartTime) && stateEndTime.isSame(preReservationEndTime)) return true

  return false
}

export const isEndtimeBeforeStarttime = reduxSearch => {
  if (!reduxSearch) return false
  if (!reduxSearch.reservationDate) return false
  if (!reduxSearch.startTime) return false
  if (!reduxSearch.endTime) return false

  var startTime = parseBackendDateStringAndTimeStringToMoment(reduxSearch.reservationDate, reduxSearch.startTime)
  if (!startTime) return false
  var endTime = parseBackendDateStringAndTimeStringToMoment(reduxSearch.reservationDate, reduxSearch.endTime)
  if (!endTime) return false

  return endTime.isBefore(startTime)
}

export const isMeetingTimeLessThan2Hours = reduxSearch => {
  if (!reduxSearch) return false
  if (!reduxSearch.reservationDate) return false
  if (!reduxSearch.startTime) return false
  if (!reduxSearch.endTime) return false

  var startTime = parseBackendDateStringAndTimeStringToMoment(reduxSearch.reservationDate, reduxSearch.startTime)
  if (!startTime) return false
  var endTime = parseBackendDateStringAndTimeStringToMoment(reduxSearch.reservationDate, reduxSearch.endTime)
  if (!endTime) return false

  // if (endTime.isBefore(startTime)) return true
  // moment().subtract(Object)
  if (endTime.subtract(2, 'hours').isBefore(startTime)) return true

  return false
}

export const differenceInDaysToCurrentDate = date => {
  if (!date) return -1

  let currentDate = moment()
  date = moment(date)
  let difference = date.diff(currentDate, 'days') + 1
  return difference
}

export const isPastLastCancellationDate = date => {
  if (parsePreReservationDateTime(date).isBefore(getCurrentTime())) return true

  return false
}
