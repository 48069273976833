export const MEETINGTYPE_MEETING = 'MEETINGTYPE_MEETING'
export const MEETINGTYPE_PARTY = 'MEETINGTYPE_PARTY'
export const MEETINGTYPE_SAUNA = 'MEETINGTYPE_SAUNA'
export const TABLECONFIGURATIONTYPE_ANYTABLEMODEL = 'TABLECONFIGURATIONTYPE_ANYTABLEMODEL'
export const TABLECONFIGURATIONTYPE_DIPLOMAT = 'TABLECONFIGURATIONTYPE_DIPLOMAT'
export const TABLECONFIGURATIONTYPE_CLASS_SHAPE = 'TABLECONFIGURATIONTYPE_CLASS_SHAPE'
export const TABLECONFIGURATIONTYPE_U_SHAPE = 'TABLECONFIGURATIONTYPE_U_SHAPE'
export const TABLECONFIGURATIONTYPE_GROUPWORK = 'TABLECONFIGURATIONTYPE_GROUPWORK'
export const TABLECONFIGURATIONTYPE_CHAIRROW = 'TABLECONFIGURATIONTYPE_CHAIRROW'
export const TABLECONFIGURATIONTYPE_COCKTAIL = 'TABLECONFIGURATIONTYPE_COCKTAIL'
export const TABLECONFIGURATIONTYPE_ROUND = 'TABLECONFIGURATIONTYPE_ROUND'
export const TABLECONFIGURATIONTYPE_EAT_TABLESERVICE = 'TABLECONFIGURATIONTYPE_EAT_TABLESERVICE'
export const TABLECONFIGURATIONTYPE_EAT_BUFFET = 'TABLECONFIGURATIONTYPE_EAT_BUFFET'

export const VENUEATTRIBUTES_MEETINGS_DAYLIGHT = 'VENUEATTRIBUTES_MEETINGS_DAYLIGHT'
export const VENUEATTRIBUTES_MEETINGS_CREATIVESPACE = 'VENUEATTRIBUTES_MEETINGS_CREATIVESPACE'
export const VENUEATTRIBUTES_MEETINGS_RESTAURANTSERVICE = 'VENUEATTRIBUTES_MEETINGS_RESTAURANTSERVICE'
export const VENUEATTRIBUTES_MEETINGS_ACCESSIBLE = 'VENUEATTRIBUTES_MEETINGS_ACCESSIBLE'
export const VENUEATTRIBUTES_SAUNA_SWIMMINGPOOL = 'VENUEATTRIBUTES_SAUNA_SWIMMINGPOOL'
export const VENUEATTRIBUTES_SAUNA_TERRACE = 'VENUEATTRIBUTES_SAUNA_TERRACE'
export const VENUEATTRIBUTES_SAUNA_MEETINGSPACE = 'VENUEATTRIBUTES_SAUNA_MEETINGSPACE'
export const VENUEATTRIBUTES_SAUNA_FESTIVESPACE = 'VENUEATTRIBUTES_SAUNA_FESTIVESPACE'
export const VENUEATTRIBUTES_CELEBRATION_DAYLIGHT = 'VENUEATTRIBUTES_CELEBRATION_DAYLIGHT'
export const VENUEATTRIBUTES_CELEBRATION_RESTAURANTSERVICE = 'VENUEATTRIBUTES_CELEBRATION_RESTAURANTSERVICE'
export const VENUEATTRIBUTES_CELEBRATION_BAND = 'VENUEATTRIBUTES_CELEBRATION_BAND'
export const VENUEATTRIBUTES_CELEBRATION_ACCESSIBLE = 'VENUEATTRIBUTES_CELEBRATION_ACCESSIBLE'
export const VENUEATTRIBUTES_CELEBRATION_STAGE = 'VENUEATTRIBUTES_CELEBRATION_STAGE'

export const FETCHING_PRODUCTS_STATUS_FETCHING = 'FETCHING_PRODUCTS_STATUS_FETCHING'
export const FETCHING_PRODUCTS_STATUS_ERROR = 'FETCHING_PRODUCTS_STATUS_ERROR'
export const FETCHING_PRODUCTS_STATUS_SUCCESS = 'FETCHING_PRODUCTS_STATUS_SUCCESS'

// TODO: Not yet in use
// export const ROOMTAGATRIBUTE_Daylight = 'TABLECONFIGURATIONTYPE_EAT_BUFFET'
// export const ROOMTAGATRIBUTE_CreativeSpace = 'TABLECONFIGURATIONTYPE_EAT_BUFFET'
// export const ROOMTAGATRIBUTE_Accessible = 'TABLECONFIGURATIONTYPE_EAT_BUFFET'
// export const ROOMTAGATRIBUTE_BuffetAvailable = 'TABLECONFIGURATIONTYPE_EAT_BUFFET'
// export const ROOMTAGATRIBUTE_TableService = 'TABLECONFIGURATIONTYPE_EAT_BUFFET'

export const timerDefaultTime = 900
export const fetchVenuesPerPage = 20

export const initialState = {
  hotelName: '', // 'E.g. url http://localhost:8000/?establishmentId=522856699 opens Original Sokos Hotel Presidentti venues'
  availabilityQuery: {
    queryStart: '',
  },
  search: {
    boxText: 'Pääkaupunkiseutu',
    term: 'Pääkaupunkiseutu',
    venueId: '', // TODO: Refactor to establishmentID
    meetingType: MEETINGTYPE_MEETING,
    tableConfigurationType: null,
    reservationDate: '', // "2019-12-12",
    startTime: null,
    endTime: null,
    personCount: 0,
    reservationId: '',
    reservationLastname: '',
    reservationURLEncryptedPart: '',
    offlineEstablishmentsOnly: false,
  },
  venueAttributeFilters: {
    meetings_daylight: false,
    meetings_creativespace: false,
    meetings_restaurantservice: false,
    meetings_accessible: false,
    sauna_swimmingpool: false,
    sauna_terrace: false,
    sauna_meetingspace: false,
    sauna_festivespace: false,
    celebration_daylight: false,
    celebration_restaurantservice: false,
    celebration_band: false,
    celebration_accessible: false,
    celebration_stage: false,
  },
  searchResults: {
    after: undefined,
    endCursor: undefined,
    hasNextPage: true,
  },
  user: {
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    companyName: '',
    VATnumber: '',
    streetAddress: '',
    zipCode: '',
    city: '',
    country: 'FI', // Preselection for CountryDropdown. Do not remove
  },
  contactPerson: {
    hasContactPerson: false,
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
  },
  paymentType: 'PaymentOnSite', // default payment method
  priceLevelCode: '',
  show: {
    searchResultsBox: false,
  },
  data: {
    edges: [],
    venueAvailability: undefined,
    venueProducts: [],
    queryError: undefined,
    reservation: undefined,
    createReservation: undefined,
    confirmReservation: undefined,
    cancelReservation: undefined,
  },
  queryStatuses: {
    // TODO: Empty these in gatsby-browser pageWrapper so that these won't prevent fetching data after page refresh
    // fetchingReservation: false,
    mutatingReservation: false,
    fetchingVenueProductsStatus: undefined,
  },
  navigation: {
    dropdown: [],
  },
}

// #region search */
const CHANGE_SEARCHTERM = 'CHANGE_SEARCHTERM'
const CHANGE_SEARCH_VENUEID = 'CHANGE_SEARCH_VENUEID'
const CHANGE_SEARCHBOXTEXT = 'CHANGE_SEARCHBOXTEXT'
const CHANGE_MEETINGTYPE = 'CHANGE_MEETINGTYPE'
const CHANGE_TABLECONFIGURATIONTYPE = 'CHANGE_TABLECONFIGURATIONTYPE'
const CHANGE_RESERVATION_DATE = 'CHANGE_RESERVATION_DATE'
const CHANGE_STARTTIME = 'CHANGE_STARTTIME'
const CHANGE_ENDTIME = 'CHANGE_ENDTIME'
const CHANGE_PERSONCOUNT = 'CHANGE_PERSONCOUNT'
const CHANGE_RESERVATION_ID = 'CHANGE_RESERVATION_ID'
const CHANGE_RESERVATION_LASTNAME = 'CHANGE_RESERVATION_LASTNAME'
const CHANGE_RESERVATION_ENCRYPTED_URL_PART = 'CHANGE_RESERVATION_ENCRYPTED_URL_PART'
const FETCH_MORE_VENUES = 'FETCH_MORE_VENUES'
const INIT_FETCH_MORE_VENUES = 'INIT_FETCH_MORE_VENUES'
const OFFLINE_ESTABLISHMENTS_ONLY = 'OFFLINE_ESTABLISHMENTS_ONLY'
const NOT_OFFLINE_ESTABLISHMENTS_ONLY = 'NOT_OFFLINE_ESTABLISHMENTS_ONLY'
const CHANGE_HOTELNAME = 'CHANGE_HOTELNAME'
const SEARCHRESULTS_HAS_NEXT_PAGE = 'SEARCHRESULTS_HAS_NEXT_PAGE'
const SEARCHRESULTS_HASNOT_NEXT_PAGE = 'SEARCHRESULTS_HASNOT_NEXT_PAGE'
const CHANGE_ENDCURSOR = 'CHANGE_ENDCURSOR'

export const changeSearchTerm = searchTerm => ({
  type: CHANGE_SEARCHTERM,
  searchTerm,
})
export const changeSearchVenueId = venueId => ({
  type: CHANGE_SEARCH_VENUEID,
  venueId,
})
export const changeSearchBoxText = text => ({
  type: CHANGE_SEARCHBOXTEXT,
  text,
})
export const changeMeetingType = meetingType => ({
  type: CHANGE_MEETINGTYPE,
  meetingType,
})
export const changeTableconfigurationType = tableConfigurationType => ({
  type: CHANGE_TABLECONFIGURATIONTYPE,
  tableConfigurationType,
})
export const changeReservationDate = reservationDate => ({
  type: CHANGE_RESERVATION_DATE,
  reservationDate,
})
export const changeStartTime = startTime => ({
  type: CHANGE_STARTTIME,
  startTime,
})
export const changeEndTime = endTime => ({
  type: CHANGE_ENDTIME,
  endTime,
})
export const changePersonCount = count => ({
  type: CHANGE_PERSONCOUNT,
  count,
})
export const changeReservationId = id => ({
  type: CHANGE_RESERVATION_ID,
  id,
})
export const changeReservationLastname = lastname => ({
  type: CHANGE_RESERVATION_LASTNAME,
  lastname,
})
export const changeReservationURLPart = reservationURLEncryptedPart => ({
  type: CHANGE_RESERVATION_ENCRYPTED_URL_PART,
  reservationURLEncryptedPart,
})
export const fetchMoreVenues = () => ({
  type: FETCH_MORE_VENUES,
})
export const initFetchMoreVenues = () => ({
  type: INIT_FETCH_MORE_VENUES,
})
export const searchResultsHasNextPage = () => ({
  type: SEARCHRESULTS_HAS_NEXT_PAGE,
})
export const searchResultsHasNotNextPage = () => ({
  type: SEARCHRESULTS_HASNOT_NEXT_PAGE,
})

export const changeEndCursor = endCursor => ({
  type: CHANGE_ENDCURSOR,
  endCursor,
})

export const offlineEstablishmentsOnly = () => ({
  type: OFFLINE_ESTABLISHMENTS_ONLY,
})
export const notOfflineEstablishmentsOnly = () => ({
  type: NOT_OFFLINE_ESTABLISHMENTS_ONLY,
})
// #endregion */

// #region VenueattributesFilters */
const CHANGE_VENUEATTRIBUTE_MEETINGS_DAYLIGHT = 'CHANGE_VENUEATTRIBUTE_MEETINGS_DAYLIGHT'
const CHANGE_VENUEATTRIBUTE_MEETINGS_CREATIVESPACE = 'CHANGE_VENUEATTRIBUTE_MEETINGS_CREATIVESPACE'
const CHANGE_VENUEATTRIBUTE_MEETINGS_RESTAURANTSERVICE = 'CHANGE_VENUEATTRIBUTE_MEETINGS_RESTAURANTSERVICE'
const CHANGE_VENUEATTRIBUTE_MEETINGS_ACCESSIBLE = 'CHANGE_VENUEATTRIBUTE_MEETINGS_ACCESSIBLE'
const CHANGE_VENUEATTRIBUTE_SAUNA_SWIMMINGPOOL = 'CHANGE_VENUEATTRIBUTE_SAUNA_SWIMMINGPOOL'
const CHANGE_VENUEATTRIBUTE_SAUNA_TERRACE = 'CHANGE_VENUEATTRIBUTE_SAUNA_TERRACE'
const CHANGE_VENUEATTRIBUTE_SAUNA_MEETINGSPACE = 'CHANGE_VENUEATTRIBUTE_SAUNA_MEETINGSPACE'
const CHANGE_VENUEATTRIBUTE_SAUNA_FESTIVESPACE = 'CHANGE_VENUEATTRIBUTE_SAUNA_FESTIVESPACE'
const CHANGE_VENUEATTRIBUTE_CELEBRATION_DAYLIGHT = 'CHANGE_VENUEATTRIBUTE_CELEBRATION_DAYLIGHT'
const CHANGE_VENUEATTRIBUTE_CELEBRATION_RESTAURANTSERVICE = 'CHANGE_VENUEATTRIBUTE_CELEBRATION_RESTAURANTSERVICE'
const CHANGE_VENUEATTRIBUTE_CELEBRATION_BAND = 'CHANGE_VENUEATTRIBUTE_CELEBRATION_BAND'
const CHANGE_VENUEATTRIBUTE_CELEBRATION_ACCESSIBLE = 'CHANGE_VENUEATTRIBUTE_CELEBRATION_ACCESSIBLE'
const CHANGE_VENUEATTRIBUTE_CELEBRATION_STAGE = 'CHANGE_VENUEATTRIBUTE_CELEBRATION_STAGE'
const INIT_VENUEATTRIBUTES = 'INIT_VENUEATTRIBUTES'

export const changeVenueAttributeMeetingsDaylight = meetings_daylight => ({
  type: CHANGE_VENUEATTRIBUTE_MEETINGS_DAYLIGHT,
  meetings_daylight,
})
export const changeVenueAttributeMeetingsCreativespace = meetings_creativespace => ({
  type: CHANGE_VENUEATTRIBUTE_MEETINGS_CREATIVESPACE,
  meetings_creativespace,
})
export const changeVenueAttributeMeetingsRestaurantservice = meetings_restaurantservice => ({
  type: CHANGE_VENUEATTRIBUTE_MEETINGS_RESTAURANTSERVICE,
  meetings_restaurantservice,
})
export const changeVenueAttributeMeetingsAccessible = meetings_accessible => ({
  type: CHANGE_VENUEATTRIBUTE_MEETINGS_ACCESSIBLE,
  meetings_accessible,
})
export const changeVenueAttributeSaunaSwimmingpool = sauna_swimmingpool => ({
  type: CHANGE_VENUEATTRIBUTE_SAUNA_SWIMMINGPOOL,
  sauna_swimmingpool,
})
export const changeVenueAttributeSaunaTerrace = sauna_terrace => ({
  type: CHANGE_VENUEATTRIBUTE_SAUNA_TERRACE,
  sauna_terrace,
})
export const changeVenueAttributeSaunaMeetingspace = sauna_meetingspace => ({
  type: CHANGE_VENUEATTRIBUTE_SAUNA_MEETINGSPACE,
  sauna_meetingspace,
})
export const changeVenueAttributeSaunaFestivespace = sauna_festivespace => ({
  type: CHANGE_VENUEATTRIBUTE_SAUNA_FESTIVESPACE,
  sauna_festivespace,
})
export const changeVenueAttributeCelebrationDaylight = celebration_daylight => ({
  type: CHANGE_VENUEATTRIBUTE_CELEBRATION_DAYLIGHT,
  celebration_daylight,
})
export const changeVenueAttributeCelebrationRestaurantservice = celebration_restaurantservice => ({
  type: CHANGE_VENUEATTRIBUTE_CELEBRATION_RESTAURANTSERVICE,
  celebration_restaurantservice,
})
export const changeVenueAttributeCelebrationBand = celebration_band => ({
  type: CHANGE_VENUEATTRIBUTE_CELEBRATION_BAND,
  celebration_band,
})
export const changeVenueAttributeCelebrationAccessible = celebration_accessible => ({
  type: CHANGE_VENUEATTRIBUTE_CELEBRATION_ACCESSIBLE,
  celebration_accessible,
})
export const changeVenueAttributeCelebrationStage = celebration_stage => ({
  type: CHANGE_VENUEATTRIBUTE_CELEBRATION_STAGE,
  celebration_stage,
})
export const initVenueAttributes = () => ({
  type: INIT_VENUEATTRIBUTES,
})
// #endregion */

/* #region SearchResultsBox */
const HIDE_SEARCHRESULTSBOX = 'HIDE_SEARCHRESULTSBOX'
const SHOW_SEARCHRESULTSBOX = 'SHOW_SEARCHRESULTSBOX'

export const showSearchResultsBox = () => ({
  type: SHOW_SEARCHRESULTSBOX,
})
export const hideSearchResultsBox = () => ({
  type: HIDE_SEARCHRESULTSBOX,
})
/* #endregion */

/* #region edges venueAvailability & venueProducts */
const CHANGE_EDGES = 'CHANGE_EDGES'
const CHANGE_VENUEAVAILABILITY = 'CHANGE_VENUEAVAILABILITY'
const CHANGE_AVAILABILITY_QUERY_START = 'CHANGE_AVAILABILITY_QUERY_START'
const CHANGE_VENUEPRODUCTS = 'CHANGE_VENUEPRODUCTS'
const ADD_PAGINATED_EDGES = 'ADD_PAGINATED_EDGES'

// const SET_FETCHING_VENUEPODUCTS_TRUE = 'SET_FETCHING_VENUEPODUCTS_TRUE'
// const SET_FETCHING_VENUEPODUCTS_FALSE = 'SET_FETCHING_VENUEPODUCTS_FALSE'

const SET_VENUEPRODUCTS_FETCHING_STATUS = 'SET_VENUEPRODUCTS_FETCHING_STATUS'

export const changeEdges = edges => ({
  type: CHANGE_EDGES,
  edges,
})
export const changeVenueAvailability = venueAvailability => ({
  type: CHANGE_VENUEAVAILABILITY,
  venueAvailability,
})
export const changeAvailabilityQueryStart = queryStart => ({
  type: CHANGE_AVAILABILITY_QUERY_START,
  queryStart,
})
export const changeVenueProducts = venueProducts => ({
  type: CHANGE_VENUEPRODUCTS,
  venueProducts,
})
export const addPaginatedEdges = edges => ({
  type: ADD_PAGINATED_EDGES,
  edges,
})

export const setVenueProductsFetchingStatus = status => ({
  type: SET_VENUEPRODUCTS_FETCHING_STATUS,
  status,
})

/* #endregion */

/* #region reservation */
const SET_QUERY_ERROR = 'SET_QUERY_ERROR'
const CHANGE_RESERVATION = 'CHANGE_RESERVATION'
const CHANGE_CREATE_RESERVATION = 'CHANGE_CREATERESERVATION'
const CHANGE_CONFIRM_RESERVATION = 'CHANGE_CONFIRM_RESERVATION'
const CHANGE_CANCEL_RESERVATION = 'CHANGE_CANCEL_RESERVATION'
// const SET_FETCHING_RESERVATION_TRUE = 'SET_FETCHING_RESERVATION_TRUE'
// const SET_FETCHING_RESERVATION_FALSE = 'SET_FETCHING_RESERVATION_FALSE'
const SET_MUTATING_RESERVATION_TRUE = 'SET_MUTATING_RESERVATION_TRUE'
const SET_MUTATING_RESERVATION_FALSE = 'SET_MUTATING_RESERVATION_FALSE'

export const setQueryError = queryError => ({
  type: SET_QUERY_ERROR,
  queryError,
})

export const changeReservation = reservation => ({
  type: CHANGE_RESERVATION,
  reservation,
})
export const changeCancelReservation = cancelReservation => ({
  type: CHANGE_CANCEL_RESERVATION,
  cancelReservation,
})
export const changeCreateReservation = createReservation => ({
  type: CHANGE_CREATE_RESERVATION,
  createReservation,
})
export const changeConfirmReservation = confirmReservation => ({
  type: CHANGE_CONFIRM_RESERVATION,
  confirmReservation,
})
// export const setFetchingReservationTrue = () => ({
//   type: SET_FETCHING_RESERVATION_TRUE,
// })
// export const setFetchingReservationFalse = () => ({
//   type: SET_FETCHING_RESERVATION_FALSE,
// })
export const setMutatingReservationTrue = () => ({
  type: SET_MUTATING_RESERVATION_TRUE,
})
export const setMutatingReservationFalse = () => ({
  type: SET_MUTATING_RESERVATION_FALSE,
})

/* #endregion */

/* #region userinfo */
const CHANGE_USER_FIRSTNAME = 'CHANGE_USER_FIRSTNAME'
const CHANGE_USER_LASTNAME = 'CHANGE_USER_LASTNAME'
const CHANGE_USER_PHONE = 'CHANGE_USER_PHONE'
const CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL'
const CHANGE_USER_COMPANYNAME = 'CHANGE_USER_COMPANYNAME'
const CHANGE_USER_VAT = 'CHANGE_USER_VAT'
const CHANGE_USER_STREETADDRESS = 'CHANGE_USER_STREETADDRESS'
const CHANGE_USER_ZIPCODE = 'CHANGE_USER_ZIPCODE'
const CHANGE_USER_CITY = 'CHANGE_USER_CITY'
const CHANGE_USER_COUNTRY = 'CHANGE_USER_COUNTRY'

export const changeUserFirstname = text => ({
  type: CHANGE_USER_FIRSTNAME,
  text,
})
export const changeUserLastname = text => ({
  type: CHANGE_USER_LASTNAME,
  text,
})
export const changeUserPhone = text => ({
  type: CHANGE_USER_PHONE,
  text,
})
export const changeUserEmail = text => ({
  type: CHANGE_USER_EMAIL,
  text,
})
export const changeUserCompanyName = text => ({
  type: CHANGE_USER_COMPANYNAME,
  text,
})
export const changeUserVAT = text => ({
  type: CHANGE_USER_VAT,
  text,
})
export const changeUserStreetAddress = text => ({
  type: CHANGE_USER_STREETADDRESS,
  text,
})
export const changeUserStreetCode = text => ({
  type: CHANGE_USER_ZIPCODE,
  text,
})
export const changeUserCity = text => ({
  type: CHANGE_USER_CITY,
  text,
})
export const changeUserCountry = text => ({
  type: CHANGE_USER_COUNTRY,
  text,
})

/* #endregion */

/* #region contactpersoninfo */
const CHANGE_CONTACTPERSON_FIRSTNAME = 'CHANGE_CONTACTPERSON_FIRSTNAME'
const CHANGE_CONTACTPERSON_LASTNAME = 'CHANGE_CONTACTPERSON_LASTNAME'
const CHANGE_CONTACTPERSON_PHONE = 'CHANGE_CONTACTPERSON_PHONE'
const CHANGE_CONTACTPERSON_EMAIL = 'CHANGE_CONTACTPERSON_EMAIL'
const CHANGE_HAS_CONTACTPERSON = 'CHANGE_HAS_CONTACTPERSON'

export const changeContactPersonFirstname = text => ({
  type: CHANGE_CONTACTPERSON_FIRSTNAME,
  text,
})
export const changeContactPersonLastname = text => ({
  type: CHANGE_CONTACTPERSON_LASTNAME,
  text,
})
export const changeContactPersonPhone = text => ({
  type: CHANGE_CONTACTPERSON_PHONE,
  text,
})
export const changeContactPersonEmail = text => ({
  type: CHANGE_CONTACTPERSON_EMAIL,
  text,
})
export const changeHasContactPerson = text => ({
  type: CHANGE_HAS_CONTACTPERSON,
  text,
})

/* #endregion */

/* #region paymenttype */

const CHANGE_PAYMENTTYPE = 'CHANGE_PAYMENTTYPE'

export const changePaymentType = text => ({
  type: CHANGE_PAYMENTTYPE,
  text,
})

/* #endregion */

/* #region price level code */

const CHANGE_PRICE_LEVEL_CODE = 'CHANGE_PRICE_LEVEL_CODE'

export const changePriceLevelCode = priceLevelCode => ({
  type: CHANGE_PRICE_LEVEL_CODE,
  priceLevelCode,
})

/* #endregion */

function sortEdges(edges) {
  if (!edges) return edges
  return edges.sort((a,b) => {
    if(a.onlineReservable && b.onlineReservable) return 0
    if(!b.onlineReservable) return -1
    return 1
  })
}

/* #region hotelname */
export const changeHotelName = text => ({
  type: CHANGE_HOTELNAME,
  text,
})
/* #endregion */

export default (state = initialState, action) => {
  switch (action.type) {
    // #region search */
    case CHANGE_SEARCHTERM:
      return {
        ...state,
        search: {
          ...state.search,
          term: action.searchTerm,
        },
      }
    case CHANGE_SEARCH_VENUEID:
      return {
        ...state,
        search: {
          ...state.search,
          venueId: action.venueId,
        },
      }
    case CHANGE_SEARCHBOXTEXT:
      return {
        ...state,
        search: {
          ...state.search,
          boxText: action.text,
        },
      }
    case CHANGE_MEETINGTYPE:
      return {
        ...state,
        search: {
          ...state.search,
          meetingType: action.meetingType,
        },
      }
    case CHANGE_TABLECONFIGURATIONTYPE:
      return {
        ...state,
        search: {
          ...state.search,
          tableConfigurationType: action.tableConfigurationType,
        },
      }
    case CHANGE_RESERVATION_DATE:
      return {
        ...state,
        search: {
          ...state.search,
          reservationDate: action.reservationDate,
        },
      }
    case CHANGE_STARTTIME:
      return {
        ...state,
        search: {
          ...state.search,
          startTime: action.startTime,
        },
      }
    case CHANGE_ENDTIME:
      return {
        ...state,
        search: {
          ...state.search,
          endTime: action.endTime,
        },
      }
    case CHANGE_PERSONCOUNT:
      return {
        ...state,
        search: {
          ...state.search,
          personCount: action.count,
        },
      }
    case CHANGE_RESERVATION_ID:
      return {
        ...state,
        search: {
          ...state.search,
          reservationId: action.id,
        },
      }
    case CHANGE_RESERVATION_LASTNAME:
      return {
        ...state,
        search: {
          ...state.search,
          reservationLastname: action.lastname,
        },
      }
    case CHANGE_RESERVATION_ENCRYPTED_URL_PART:
      return {
        ...state,
        search: {
          ...state.search,
          reservationURLEncryptedPart: action.reservationURLEncryptedPart,
        },
      }
    case INIT_FETCH_MORE_VENUES:
      return {
        ...state,
        searchResults: {
          after: undefined,
          endCursor: undefined,
          hasNextPage: true,
        },
      }
    case FETCH_MORE_VENUES:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          after: state.searchResults.endCursor ? state.searchResults.endCursor.valueOf() : undefined,
        },
      }
    case SEARCHRESULTS_HAS_NEXT_PAGE:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          hasNextPage: true,
        },
      }
    case CHANGE_ENDCURSOR:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          endCursor: action.endCursor,
        },
      }
    case SEARCHRESULTS_HASNOT_NEXT_PAGE:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          hasNextPage: false,
        },
      }
    case OFFLINE_ESTABLISHMENTS_ONLY:
      return {
        ...state,
        search: {
          ...state.search,
          offlineEstablishmentsOnly: true,
        },
      }
    case NOT_OFFLINE_ESTABLISHMENTS_ONLY:
      return {
        ...state,
        search: {
          ...state.search,
          offlineEstablishmentsOnly: false,
        },
      }
    // #endregion */

    // #region venueAttributeFilters */
    case CHANGE_VENUEATTRIBUTE_MEETINGS_DAYLIGHT:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          meetings_daylight: action.meetings_daylight,
        },
      }
    case CHANGE_VENUEATTRIBUTE_MEETINGS_CREATIVESPACE:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          meetings_creativespace: action.meetings_creativespace,
        },
      }
    case CHANGE_VENUEATTRIBUTE_MEETINGS_RESTAURANTSERVICE:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          meetings_restaurantservice: action.meetings_restaurantservice,
        },
      }
    case CHANGE_VENUEATTRIBUTE_MEETINGS_ACCESSIBLE:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          meetings_accessible: action.meetings_accessible,
        },
      }
    case CHANGE_VENUEATTRIBUTE_SAUNA_SWIMMINGPOOL:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          sauna_swimmingpool: action.sauna_swimmingpool,
        },
      }
    case CHANGE_VENUEATTRIBUTE_SAUNA_TERRACE:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          sauna_terrace: action.sauna_terrace,
        },
      }
    case CHANGE_VENUEATTRIBUTE_SAUNA_MEETINGSPACE:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          sauna_meetingspace: action.sauna_meetingspace,
        },
      }
    case CHANGE_VENUEATTRIBUTE_SAUNA_FESTIVESPACE:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          sauna_festivespace: action.sauna_festivespace,
        },
      }
    case CHANGE_VENUEATTRIBUTE_CELEBRATION_DAYLIGHT:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          celebration_daylight: action.celebration_daylight,
        },
      }
    case CHANGE_VENUEATTRIBUTE_CELEBRATION_RESTAURANTSERVICE:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          celebration_restaurantservice: action.celebration_restaurantservice,
        },
      }
    case CHANGE_VENUEATTRIBUTE_CELEBRATION_BAND:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          celebration_band: action.celebration_band,
        },
      }
    case CHANGE_VENUEATTRIBUTE_CELEBRATION_ACCESSIBLE:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          celebration_accessible: action.celebration_accessible,
        },
      }
    case CHANGE_VENUEATTRIBUTE_CELEBRATION_STAGE:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          celebration_stage: action.celebration_stage,
        },
      }
    case INIT_VENUEATTRIBUTES:
      return {
        ...state,
        venueAttributeFilters: {
          ...state.venueAttributeFilters,
          meetings_daylight: false,
          meetings_creativespace: false,
          meetings_restaurantservice: false,
          meetings_accessible: false,
          sauna_swimmingpool: false,
          sauna_terrace: false,
          sauna_meetingspace: false,
          sauna_festivespace: false,
          celebration_daylight: false,
          celebration_restaurantservice: false,
          celebration_band: false,
          celebration_accessible: false,
          celebration_stage: false,
        },
      }
    /* #endregion venueAttributeFilters*/

    /* #region SearchResultsBox */
    case SHOW_SEARCHRESULTSBOX:
      if (state.show.searchResultsBox) return state
      return {
        ...state,
        show: {
          ...state.show,
          searchResultsBox: true,
        },
      }
    case HIDE_SEARCHRESULTSBOX:
      if (!state.show.searchResultsBox) return state
      return {
        ...state,
        show: {
          ...state.show,
          searchResultsBox: false,
        },
      }
    /* #endregion */

    /* #region edges availability & venueProducts */
    case CHANGE_EDGES:
      return {
        ...state,
        data: {
          ...state.data,
          edges: sortEdges(action.edges),
        },
      }
    case ADD_PAGINATED_EDGES:
      if (!state.data.edges) return state
      let newEdges = state.data.edges.slice().concat(action.edges)
      return {
        ...state,
        data: {
          ...state.data,
          edges: sortEdges(newEdges),
        },
      }
    case CHANGE_VENUEAVAILABILITY:
      return {
        ...state,
        data: {
          ...state.data,
          venueAvailability: action.venueAvailability,
        },
      }
    case CHANGE_AVAILABILITY_QUERY_START:
      return {
        ...state,
        availabilityQuery: {
          ...state.availabilityQuery,
          queryStart: action.queryStart,
        },
      }
    case CHANGE_VENUEPRODUCTS:
      return {
        ...state,
        data: {
          ...state.data,
          venueProducts: action.venueProducts,
        },
      }

    case SET_VENUEPRODUCTS_FETCHING_STATUS:
      return {
        ...state,
        queryStatuses: {
          ...state.queryStatuses,
          fetchingVenueProductsStatus: action.status,
        },
      }
    /* #endregion */

    /* #region reservation */
    case SET_QUERY_ERROR: {
      return {
        ...state,
        data: {
          ...state.data,
          queryError: action.queryError,
        },
      }
    }

    case CHANGE_RESERVATION:
      return {
        ...state,
        data: {
          ...state.data,
          reservation: action.reservation,
        },
      }

    case CHANGE_CREATE_RESERVATION:
      return {
        ...state,
        data: {
          ...state.data,
          createReservation: action.createReservation,
        },
      }
    case CHANGE_CANCEL_RESERVATION:
      return {
        ...state,
        data: {
          ...state.data,
          cancelReservation: action.cancelReservation,
        },
      }
    case CHANGE_CONFIRM_RESERVATION:
      return {
        ...state,
        data: {
          ...state.data,
          confirmReservation: action.confirmReservation,
        },
      }
    // case SET_FETCHING_RESERVATION_TRUE:
    //   if (state.queryStatuses.fetchingReservation) return state
    //   return {
    //     ...state,
    //     queryStatuses: {
    //       ...state.queryStatuses,
    //       fetchingReservation: true,
    //     },
    //   }
    // case SET_FETCHING_RESERVATION_FALSE:
    //   if (!state.queryStatuses.fetchingReservation) return state
    //   return {
    //     ...state,
    //     queryStatuses: {
    //       ...state.queryStatuses,
    //       fetchingReservation: false,
    //     },
    //   }
    case SET_MUTATING_RESERVATION_TRUE:
      if (state.queryStatuses.mutatingReservation) return state
      return {
        ...state,
        queryStatuses: {
          ...state.queryStatuses,
          mutatingReservation: true,
        },
      }
    case SET_MUTATING_RESERVATION_FALSE:
      if (!state.queryStatuses.mutatingReservation) return state
      return {
        ...state,
        queryStatuses: {
          ...state.queryStatuses,
          mutatingReservation: false,
        },
      }
    /* #endregion */

    /* #region userinfo */
    case CHANGE_USER_FIRSTNAME:
      return {
        ...state,
        user: {
          ...state.user,
          firstname: action.text,
        },
      }
    case CHANGE_USER_LASTNAME:
      return {
        ...state,
        user: {
          ...state.user,
          lastname: action.text,
        },
      }
    case CHANGE_USER_PHONE:
      return {
        ...state,
        user: {
          ...state.user,
          phone: action.text,
        },
      }
    case CHANGE_USER_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.text,
        },
      }
    case CHANGE_USER_COMPANYNAME:
      return {
        ...state,
        user: {
          ...state.user,
          companyName: action.text,
        },
      }
    case CHANGE_USER_VAT:
      return {
        ...state,
        user: {
          ...state.user,
          VATnumber: action.text,
        },
      }
    case CHANGE_USER_STREETADDRESS:
      return {
        ...state,
        user: {
          ...state.user,
          streetAddress: action.text,
        },
      }
    case CHANGE_USER_ZIPCODE:
      return {
        ...state,
        user: {
          ...state.user,
          zipCode: action.text,
        },
      }
    case CHANGE_USER_CITY:
      return {
        ...state,
        user: {
          ...state.user,
          city: action.text,
        },
      }
    case CHANGE_USER_COUNTRY:
      return {
        ...state,
        user: {
          ...state.user,
          country: action.text,
        },
      }
    /* #endregion */

    /* #region contactpersoninfo */
    case CHANGE_CONTACTPERSON_FIRSTNAME:
      return {
        ...state,
        contactPerson: {
          ...state.contactPerson,
          firstname: action.text,
        },
      }
    case CHANGE_CONTACTPERSON_LASTNAME:
      return {
        ...state,
        contactPerson: {
          ...state.contactPerson,
          lastname: action.text,
        },
      }
    case CHANGE_CONTACTPERSON_PHONE:
      return {
        ...state,
        contactPerson: {
          ...state.contactPerson,
          phone: action.text,
        },
      }
    case CHANGE_CONTACTPERSON_EMAIL:
      return {
        ...state,
        contactPerson: {
          ...state.contactPerson,
          email: action.text,
        },
      }
    case CHANGE_HAS_CONTACTPERSON:
      return {
        ...state,
        contactPerson: {
          ...state.contactPerson,
          hasContactPerson: action.text,
        },
      }
    /* #endregion */

    /* #region paymenttype */
    case CHANGE_PAYMENTTYPE:
      return {
        ...state,
        paymentType: action.text,
      }
    /* #endregion */

    /* #region price level code  */
    case CHANGE_PRICE_LEVEL_CODE:
      return {
        ...state,
        priceLevelCode: action.priceLevelCode,
      }
    /* #endregion */

    /* #region hotelname */
    case CHANGE_HOTELNAME:
      return {
        ...state,
        hotelName: action.text,
      }
    /* #endregion */
    default:
      return state
  }
}
