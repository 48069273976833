export default () => {
  const appElem = document.createElement('script')
  // 3.32.0 requires CSP changes
  appElem.src = 'https://app.usercentrics.eu/browser-ui/3.31.0/loader.js'
  appElem.id = 'usercentrics-cmp'
  appElem.async = true

  switch (process.env.GATSBY_ACTIVE_ENV) {
    case 'production':
      appElem.setAttribute('data-settings-id', 'Yv591VkI5')
      break
    default:
      appElem.setAttribute('data-settings-id', 'od4iU5RUI')
  }

  document.head.appendChild(appElem)

  return null
}
