import { initialState } from '../state/reduxApp'
import { parseBackendDateToDate } from './timeService'
import moment from 'moment'

const parseEstablishmentId = location => {
  if (!location) return
  if (!location.search) return

  if (location.search.includes('establishmentId')) {
    try {
      return location.search.replace('?establishmentId=', '')
    } catch (err) {
      return
    }
  }
}
const parseReservationURL = location => {
  if (!location) return ''
  if (!location.search) return ''
  if (location.pathname.includes('reserv') || location.pathname.includes('varaus')) {
    const { search } = location
    try {
      if (search.indexOf('lastname') < 0) {
        return { reservationURLEncryptedPart: search.substring(1) }
      } else {
        const decodedSearch = decodeURIComponent(search)

        const indexOfAmpersand = decodedSearch.indexOf('&')
        return { reservationId: decodedSearch.substring(4, indexOfAmpersand), reservationLastname: decodedSearch.substring(indexOfAmpersand + 10) }
      }
    } catch (err) {
      return
    }
  }
}
const setLanguage = (props, preloadedState) => {
  // Set new language to preloadedState based on url path
  preloadedState.language = 'fi'
  if (props.location.pathname.includes('/sv')) preloadedState.language = 'sv'
  if (props.location.pathname.includes('/en')) preloadedState.language = 'en'
  if (props.location.pathname.includes('/et')) preloadedState.language = 'et'
  return preloadedState
}
const setInitialSearchTerm = preloadedState => {
  // When entering tilakauppa, the default venues to be seen are capital area venues
  let initialSearchTerm
  switch (preloadedState.language) {
    case 'fi':
      initialSearchTerm = 'Pääkaupunkiseutu'
      break
    case 'sv':
      initialSearchTerm = 'Huvudstadsregionen'
      break
    case 'en':
      initialSearchTerm = 'Capital Area'
      break
    case 'et':
      initialSearchTerm = 'Pealinnaregiooni'
      break
    default:
      break
  }
  // Override default init redux state boxText & term with localized term
  if (
    preloadedState.search.boxText === 'Pääkaupunkiseutu' ||
    preloadedState.search.boxText === 'Huvudstadsregionen' ||
    preloadedState.search.boxText === 'Capital Area' ||
    preloadedState.search.boxText === 'Столичный регион' ||
    preloadedState.search.boxText === 'Pealinnaregiooni'
  ) {
    preloadedState.search.boxText = initialSearchTerm
    preloadedState.search.term = initialSearchTerm
  }
  return preloadedState
}
const emptyProperties = preloadedState => {
  // Empty fetched data
  preloadedState.data.edges = []
  preloadedState.data.createReservation = undefined
  preloadedState.data.cancelReservation = undefined
  preloadedState.data.venueAvailability = undefined

  // Empty searchResults
  preloadedState.searchResults = {
    after: undefined,
    endCursor: undefined,
    hasNextPage: true,
  }

  // Empty priceLevelCode
  preloadedState.priceLevelCode = ''

  // Empty venueAttributeFilters
  preloadedState.venueAttributeFilters = {
    meetings_daylight: false,
    meetings_creativespace: false,
    meetings_restaurantservice: false,
    meetings_accessible: false,
    sauna_swimmingpool: false,
    sauna_terrace: false,
    sauna_meetingspace: false,
    sauna_festivespace: false,
    celebration_daylight: false,
    celebration_restaurantservice: false,
    celebration_band: false,
    celebration_accessible: false,
    celebration_stage: false,
  }

  return preloadedState
}
const setEstablishmentIds = (props, preloadedState) => {
  // Test urls
  // Original Sokos Hotel Presidentti
  // http://localhost:8000/?establishmentId=522856699
  // Original Sokos Hotel Pasila
  // http://localhost:8000/?establishmentId=533704490
  // Original Sokos Hotel Vantaa
  // http://localhost:8000/?establishmentId=550256390

  var establishmentId = parseEstablishmentId(props.location)
  if (establishmentId) {
    preloadedState.search.venueId = establishmentId
    preloadedState.search.boxText = ''
    preloadedState.search.term = ''
  }
  return preloadedState
}
const emptyCurrentDay = preloadedState => {
  // Empty date if day not allowed in search page queries to fetch data
  let reservationDate = parseBackendDateToDate(preloadedState.search.reservationDate)
  if (
    moment()
      .add(1, 'day')
      .isSame(reservationDate, 'day')
  )
    preloadedState.search.reservationDate = ''
  if (
    moment()
      .add(2, 'day')
      .isSame(reservationDate, 'day')
  )
    preloadedState.search.reservationDate = ''

  return preloadedState
}

const emptyAvailabilityQueryStart = preloadedState => {
  preloadedState.availabilityQuery.queryStart = ''

  return preloadedState
}
const setReservation = (props, preloadedState) => {
  // console.log("Preload state to redux")
  // Reservation url
  // http://localhost:8000/en/reservation/?DEGYg-8uGYTVYEkPwONu63ygg74QYI0C5_Ne0LJF83JIW3yaueH9FMU_KBOkH_qKXkNzv8kYPsCto6JL0w
  // http://localhost:8000/varaus/?UdjAdjJEEGnpBdMKgAdTrhEAJUE_d5FRNPyikOLzp2tHR6WDjM7chy2t2mgblRR7uLdq2qd6T_i5w89zrw
  // Old reservation URL
  // http://localhost:8000/varaus/?id=1523228588&lastname=Kobranen
  const parsed = parseReservationURL(props.location)
  if (parsed) {
    if (parsed.reservationURLEncryptedPart) {
      preloadedState.search.reservationURLEncryptedPart = parsed.reservationURLEncryptedPart
    } else {
      preloadedState.search.reservationId = parsed.reservationId
      preloadedState.search.reservationLastname = parsed.reservationLastname
    }
  }

  return preloadedState
}

export const manipulateState = (props, PAGE_REFRESHED) => {
  let preloadedState = null
  if (!PAGE_REFRESHED && props.location.state && props.location.state.search) {
    // console.log("Preloaded state created based on location.state")
    preloadedState = props.location.state
  } else {
    // console.log('Page refresh or first entry detected')
    // console.log("Preloaded state created based on reduxApp initialState")
    preloadedState = initialState
  }

  preloadedState = setLanguage(props, preloadedState)
  preloadedState = setInitialSearchTerm(preloadedState)
  preloadedState = emptyProperties(preloadedState)
  preloadedState = emptyCurrentDay(preloadedState)
  preloadedState = emptyAvailabilityQueryStart(preloadedState)
  preloadedState = setEstablishmentIds(props, preloadedState)
  preloadedState = setReservation(props, preloadedState)
  return preloadedState
}

export const isIE = () => {
  if (window.location.href.includes('ienotsupported')) return true
  if (window.navigator.userAgent.includes('Trident/') || window.navigator.userAgent.includes('MSIE')) {
    window.location.href = '/ienotsupported'
    return true
  }
  return false
}
