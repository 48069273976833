module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NGZWLQ3","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../plugins/gatsby-plugin-intl-custom/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/sok-digikehitys/sokoshotels-website/meetings/frontend/src/intl","languages":["fi","sv","en","et"],"defaultLanguage":"fi","redirect":false,"localizedReservationPaths":{"fi":"/varaus","sv":"/reservation","en":"/reservation","et":"/reserveerimine"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
