import './src/styles/global.css'
import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { client } from './src/apollo/client'
import ReduxWrapper from './src/state/reduxWrapper'
import { manipulateState, isIE } from './src/services/browserService'
import Cookie from './src/cookies/cookie'

export const wrapRootElement = ({ element }) => <ApolloProvider client={client}>{element}</ApolloProvider>

var PAGE_REFRESHED = true
export const onClientEntry = () => {
  // Set PAGE_REFRESHED info false after little delay, so that browserService can handle redux preloadedState creation
  setTimeout(function() {
    PAGE_REFRESHED = false
  }, 1000)
}

/*
   1. WrapPageElement gets Gatsby props link location state and pass it to Redux wrapper as preloadedState. ManipulateState handles that.
   This is done to pass Redux state between pages.
   So you MUST add state to all Gatsby Links e.g.:
   <Link
      to={`/${roomUrl}`}
      state={this.props.reduxState}
   >
   const mapStateToProps = state => {
      return {
         reduxState: state
      }
   }

   OR

   navigate(linkTo, { replace: true, state: this.props.reduxState })

   2. If location state doesn't contain reduxState the initialState is used as preloadedState

   --------

   This wrapper also get language from location.pathname and sets it to preloadedState's language
*/
export const wrapPageElement = ({ element, props }) => {
  if (isIE()) return

  return (
    <>
      <Cookie />
      <ReduxWrapper preloadedState={manipulateState(props, PAGE_REFRESHED)}>{element}</ReduxWrapper>
    </>
  )
}
