import React from 'react'
import { Provider } from 'react-redux'
import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import app from './reduxApp'

const initStore = preloadedState => {
  const windowGlobal = typeof window !== 'undefined' && window

  const devtools = process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : f => f

  if (preloadedState) {
    return createStore(app, preloadedState, compose(applyMiddleware(thunk), devtools))
  }

  return createStore(app, compose(applyMiddleware(thunk), devtools))
}

export default props => {
  return <Provider store={initStore(props.preloadedState)}>{props.children}</Provider>
}
