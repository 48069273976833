import ApolloClient from 'apollo-client'
import fetch from 'isomorphic-fetch'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from './fragmentTypes.json'

const httpLink = createHttpLink({
  uri: process.env.GATSBY_API_URL,
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      client_id: process.env.GATSBY_API_CLIENTID,
    },
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ fragmentMatcher }),
  fetch,
})
